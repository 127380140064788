import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import '@adia/react-ui-building-blocks/style.css';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

const tagManagerArgs : TagManagerArgs = {
	gtmId: 'GTM-NQ88L26',
	events: {
		'gtm.start': new Date().getTime(),
		event: 'gtm.js'
	}
};

TagManager.initialize(tagManagerArgs);

async function render() {
	const { App } = await import('./app/App');
	ReactDOM.render(<App />, document.getElementById('root'));
}

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
	module.hot.accept('./app/App', render);
}
